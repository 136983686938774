import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
//import 'bootswatch/dist/cyborg/bootstrap.min.css';

function Footer() {
    return (
        <Container fluid className="mt-5">
            <Row>
                <Col md={6} className="text-center">
                    <p>Powered by <a href="https://getbootstrap.com/" target="_blank" rel="noopener noreferrer">Bootstrap</a></p>
                </Col>
                <Col md={6} className="text-center">
                    <p>Copyrightⓒ <a href="/" target="_blank" rel="noopener noreferrer">OULTER LOGICS.</a></p>
                </Col>
            </Row>
        </Container>
    );
}

export default Footer;
