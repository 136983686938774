import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faCloud, faCloudRain, faSnowflake, faSmog, faWind } from '@fortawesome/free-solid-svg-icons';

//import 'bootstrap/dist/css/bootstrap.min.css';

const API_KEY = '0706855c8acad5c8171a3004ca724ddf';

function Weather() {
    const [city, setCity] = useState({value: "Seoul,KR", label: "서울", country: "대한민국", lat: 37.5665, lon: 126.9780});
    const [weatherInfo, setWeatherInfo] = useState(null);

    const getWeather = () => {
        const url = `https://api.openweathermap.org/data/2.5/weather?q=${city.value}&appid=${API_KEY}&lang=kr&units=metric`;
        fetch(url)
            .then(response => response.json())
            .then(data => {
                setWeatherInfo({
                    temp: data.main.temp,
                    description: data.weather[0].description
                });
            })
            .catch(error => {
                console.error("날씨 정보를 가져오는 데 실패했습니다.", error);
            });
    };

    const openWindy = () => {
        const currentCity = cities.find(c => c.value === city.value);
        console.log(currentCity);
        const windyURL = `https://www.windy.com/?${currentCity.lat},${currentCity.lon},5`;
        window.open(windyURL, '_blank', 'width=800, height=600');
    };
    
    const openFlightAware = () => {
        const currentCity = cities.find(c => c.value === city.value);
        console.log(currentCity);
        // FlightAware URL 패턴은 아래와 같이 임의로 설정하였습니다.
        const flightAwareURL = `https://ko.flightaware.com/live/findflight?origin=${currentCity.origin}&destination=${currentCity.destination}`;
        window.open(flightAwareURL, '_blank', 'width=800, height=600');
    };
    
    
    const getWeatherIcon = (description) => {
        description = description.toLowerCase();
        if (description.includes("clear") || description.includes("맑음")) return faSun;
        if (description.includes("cloud") || description.includes("구름")) return faCloud;
        if (description.includes("rain") || description.includes("비")) return faCloudRain;
        if (description.includes("snow") || description.includes("눈")) return faSnowflake;
        if (description.includes("mist") || description.includes("smoke") || description.includes("haze") || description.includes("안개") || description.includes("연기")) return faSmog;
        if (description.includes("wind") || description.includes("바람")) return faWind;
        return faSun; // Default icon
    };
    
    const cities = [
        {value: "Seoul,KR", label: "서울", country: "대한민국", lat: 37.5665, lon: 126.9780, origin: "ICN", destination: "ICN"},
        {value: "New York,US", label: "뉴욕", country: "미국", lat: 40.7128, lon: -74.0060, origin: "ICN", destination: "ZNY"},
        {value: "Los Angeles,US", label: "로스앤젤레스", country: "미국", lat: 34.0522, lon: -118.2437, origin: "ICN", destination: "KLAX"},
        {value: "London,GB", label: "런던", country: "영국", lat: 51.5074, lon: -0.12780060, origin: "ICN", destination: "ZLN"},
        {value: "Jakarta,ID", label: "자카르타", country: "인도네시아", lat: -6.2088, lon: 106.8456, origin: "ICN", destination: "WIII"},
        {value: "Tokyo,JP", label: "도쿄", country: "일본", lat: 35.6895, lon: 139.6917, origin: "ICN", destination: "ZTY"},
        {value: "Paris,FR", label: "파리", country: "프랑스", lat: 48.8566, lon: 2.3522, origin: "ICN", destination: "LFPG"},
        {value: "Berlin,DE", label: "베를린", country: "독일", lat: 52.5200, lon: 13.4050, origin: "ICN", destination: "EDDB"},
        {value: "Sydney,AU", label: "시드니", country: "호주", lat: -33.8688, lon: 151.2093, origin: "ICN", destination: "YSSY"},
        {value: "Moscow,RU", label: "모스크바", country: "러시아", lat: 55.7558, lon: 37.6173, origin: "ICN", destination: "UUEE"},
        {value: "Beijing,CN", label: "베이징", country: "중국", lat: 39.9042, lon: 116.4074, origin: "ICN", destination: "ZBAA"},
        {value: "Dubai,AE", label: "두바이", country: "UAE", lat: 25.276987, lon: 55.296249, origin: "ICN", destination: "OMDB"},
        {value: "Rio de Janeiro,BR", label: "리우데자네이루", country: "브라질", lat: -22.9068, lon: -43.1729, origin: "ICN", destination: "SBRJ"},
        {value: "Cape Town,ZA", label: "케이프타운", country: "남아프리카", lat: -33.9249, lon: 18.4241, origin: "ICN", destination: "FACT"},
        {value: "Mumbai,IN", label: "뭄바이", country: "인도", lat: 19.0760, lon: 72.8777, origin: "ICN", destination: "VABB"},
        {value: "Bangkok,TH", label: "방콕", country: "태국", lat: 13.7563, lon: 100.5018, origin: "ICN", destination: "VTBS"},
        {value: "Dubai,AE", label: "두바이", country: "아랍에미리트", lat: 25.276987, lon: 55.296249, origin: "ICN", destination: "OMDB"},
        {value: "Toronto,CA", label: "토론토", country: "캐나다", lat: 43.651070, lon: -79.347015, origin: "ICN", destination: "CYYZ"},
        {value: "Berlin,DE", label: "베를린", country: "독일", lat: 52.5200, lon: 13.4050, origin: "ICN", destination: "EDDB"}, // 이 항목은 중복되어 있으므로 제거할 수 있습니다.
        {value: "Rome,IT", label: "로마", country: "이탈리아", lat: 41.9028, lon: 12.4964, origin: "ICN", destination: "LIRU"},
        {value: "Cairo,EG", label: "카이로", country: "이집트", lat: 30.802498, lon: 31.265289, origin: "ICN", destination: "HECA"},
        {value: "Buenos Aires,AR", label: "부에노스아이레스", country: "아르헨티나", lat: -34.6037, lon: -58.3816, origin: "ICN", destination: "MRBA"},
        {value: "Singapore,SG", label: "싱가포르", country: "싱가포르", lat: 1.3521, lon: 103.8198, origin: "ICN", destination: "WSSS"}
    ];

    useState(() => {
        getWeather();
    }, [city]);

    return (
        <div className="container mt-5">
            <h1>현재 날씨 정보</h1>
            <div className="mb-3">
            <select className="form-select" onChange={(e) => {
                const selectedCity = cities.find(c => c.value === e.target.value);
                
                setCity(selectedCity);
                getWeather();
            }}>
                {cities.map(c => <option key={c.value} value={c.value}>{c.label}({c.country})</option>)}
            </select>
            </div>
            {/* <button className="btn btn-primary" onClick={getWeather}>날씨 확인</button> */}
            {weatherInfo && (
                <div className="mt-4">
                    <p>도시: {city.label}</p>
                    <p>기온: {weatherInfo.temp}°C</p>
                    <p>상태: <FontAwesomeIcon icon={getWeatherIcon(weatherInfo.description)} /> {weatherInfo.description}</p>
                    <p>
                        <button onClick={openWindy}>Windy 보기</button>
                        <button onClick={openFlightAware}>FlightAware 보기</button>
                    </p>
                </div>
            )}
        </div>
    );
}

export default Weather;