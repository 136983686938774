import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';

function Lotto() {
  const [type, setType] = useState('lunar'); // 초기 기본 유형을 음력으로 설정
  const [numbers, setNumbers] = useState([]);
  const [date, setDate] = useState({
    year: "",
    month: "",
    day: ""
  });
  const [birthDate, setBirthDate] = useState("");
  const [colors, setColors] = useState(["#000000", "#000000", "#000000"]);
  const [error, setError] = useState("");

  // 명리학 로직들 ...
  const generateNumbersFromLunar = () => {
    if (!isValidLunarDate(date)) {
      setError("올바른 음력 날짜를 입력해주세요.");
      return;
    }
    setError(""); // clear error

    const baseNumbers = [
      parseInt(date.year) % 45,
      parseInt(date.month) % 45,
      parseInt(date.day) % 45
    ];

    while (baseNumbers.length < 6) {
      const num = Math.floor(Math.random() * 45) + 1;
      if (!baseNumbers.includes(num)) {
        baseNumbers.push(num);
      }
    }

    baseNumbers.sort((a, b) => a - b);
    setNumbers(baseNumbers);
  };

  const isValidLunarDate = (date) => {
    const { year, month, day } = date;

    // 월과 일이 숫자인지 확인
    if (!year || !month || !day || isNaN(month) || isNaN(day)) {
      return false;
    }

    // 월 범위 확인
    if (month < 1 || month > 12) {
      return false;
    }

    // 일 범위 확인 (단순화 된 검사; 윤년에 대한 로직은 포함되지 않았음)
    if (day < 1 || day > 30 || (month === 2 && day > 29)) {
      return false;
    }

    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDate(prev => ({ ...prev, [name]: value }));
  };

  const resetLunarDate = () => {
    setDate({
        year: "",
        month: "",
        day: ""
      });
    setError(""); // Clear error message
  };

  // 별자리 로직들 ...
  const initialZodiacs = [];
  const zodiacSigns = [
    { start: "01-20", end: "02-18", number: 1 }, 
    { start: "02-19", end: "03-20", number: 2 }, 
    { start: "03-21", end: "04-19", number: 3 }, 
    { start: "04-20", end: "05-20", number: 4 }, 
    { start: "05-21", end: "06-20", number: 5 }, 
    { start: "06-21", end: "07-22", number: 6 }, 
    { start: "07-23", end: "08-22", number: 7 }, 
    { start: "08-23", end: "09-22", number: 8 }, 
    { start: "09-23", end: "10-22", number: 9 }, 
    { start: "10-23", end: "11-21", number: 10 },
    { start: "11-22", end: "12-21", number: 11 }, 
    { start: "12-22", end: "01-19", number: 12 } 
  ];

  const generateNumbersFromZodiac = () => {
    if (!isValidBirthDate(birthDate)) {
      setError("올바른 생일을 입력해주세요.");
      return;
    }
    setError(""); // clear error

    const monthDay = birthDate.slice(5);
    const zodiac = zodiacSigns.find(z => monthDay >= z.start && monthDay <= z.end);
    const baseNumber = zodiac ? zodiac.number : 1;

    const generatedNumbers = [baseNumber];

    while (generatedNumbers.length < 6) {
      const num = Math.floor(Math.random() * 45) + 1;
      if (!generatedNumbers.includes(num)) {
        generatedNumbers.push(num);
      }
    }

    generatedNumbers.sort((a, b) => a - b);
    setNumbers(generatedNumbers);
  };

  const resetZodiacs = () => {
    setBirthDate(initialZodiacs);
    setError(""); // Clear error message
  };
  
  // Color 로직들 ...
  const isValidBirthDate = (date) => {
    if (!date || typeof date !== "string") {
      return false;
    }
    
    if (!date) {
      return false;
    }

    const [year, month, day] = date.split("-").map(num => parseInt(num, 10));

    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      return false;
    }

    if (month < 1 || month > 12) {
      return false;
    }

    const maxDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (day < 1 || day > maxDays[month - 1]) {
      return false;
    }

    return true;
  };

  const initialColors = ["#000000", "#000000", "#000000"];

  const generateNumbersFromColors = () => {
    setError(""); // Clear previous error

    if (!areColorsValid(colors)) {
      setError("각 색상을 선택해주세요.");
      return;
    }

    const baseNumbers = colors.map(color => {
      const rgb = hexToRgb(color);
      return (rgb.r + rgb.g + rgb.b) % 45 + 1;
    });

    while (baseNumbers.length < 6) {
      const num = Math.floor(Math.random() * 45) + 1;
      if (!baseNumbers.includes(num)) {
        baseNumbers.push(num);
      }
    }

    setNumbers(baseNumbers.sort((a, b) => a - b));
  };

  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  };

  const handleColorChange = (index, event) => {
    const newColors = [...colors];
    newColors[index] = event.target.value;
    setColors(newColors);
  };

  const resetColors = () => {
    setColors(initialColors);
    setError(""); // Clear error message
  };

  const areColorsValid = (colors) => {
    return colors.every(color => color !== "" && color !== "#000000");
  };

  return (
    <div className="container mt-5">
      <h1 className="mb-4">로또 번호 생성기</h1>
      
      {error && <Alert variant="danger">{error}</Alert>}
      
      <div className="mb-3">
        {/*<label className="form-label">유형 선택</label>*/}
        <select className="form-select" value={type} onChange={(e) => setType(e.target.value)}>
          <option value="lunar">음력생일 기반</option>
          <option value="zodiac">별자리 기반</option>
          <option value="color">색상 기반</option>
        </select>
      </div>

      {type === 'lunar' && (
        <div className="mb-3">
          <div className="input-group mb-3">
            <input type="text" className="form-control" name="year" placeholder="년" value={date.year} onChange={handleChange} />
            <input type="text" className="form-control" name="month" placeholder="월" value={date.month} onChange={handleChange} />
            <input type="text" className="form-control" name="day" placeholder="일" value={date.day} onChange={handleChange} />
          </div>
          <button className="btn btn-secondary me-2" onClick={resetLunarDate}>초기화</button>
          <button className="btn btn-primary" onClick={generateNumbersFromLunar}>번호 생성</button>
        </div>
      )}

      {type === 'zodiac' && (
        <div className="mb-3">
          <input type="date" className="form-control mb-3" value={birthDate} onChange={(e) => setBirthDate(e.target.value)} />
          <button className="btn btn-secondary me-2" onClick={resetZodiacs}>초기화</button>
          <button className="btn btn-primary" onClick={generateNumbersFromZodiac}>번호 생성</button>
        </div>
      )}

{type === 'color' && (
            <div className="mb-3">
                <div className="mb-3">
                {colors.map((color, index) => (
                    <div className="me-2 d-inline-block">
                        <input
                            key={index}
                            type="color"
                            className="form-control form-control-color"
                            value={color}
                            onChange={(e) => handleColorChange(index, e)}
                            style={{ width: '50px', height: '50px' }} // 색상 선택기 크기 조절
                        />
                    </div>
                ))}
                </div>
                <button className="btn btn-secondary me-2" onClick={resetColors}>초기화</button>
                <button className="btn btn-primary" onClick={generateNumbersFromColors}>번호 생성</button>
            </div>
        )}

      <Alert className="mt-4" variant="info">
        {numbers.map((number, index) => (
          <span key={index} className="me-2">{number}</span>
        ))}
      </Alert>
    </div>
  );
}

export default Lotto;