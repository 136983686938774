import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';
import CustomNavbar from './components/Navbar';
import AgeCalculator from './components/AgeCalculator';
import PerCalculator from './components/PerCalculator';
import InterestCalculator from './components/InterestCalculator';
import Calculator from './components/Calculator';
import ECalculator from './components/E_Calculator';
import PrettierFormatter from './components/PrettierFormatter';
import Weather from './components/Weather';
import Lotto from './components/Lotto';
import Footer from './components/Footer';
import 'bootswatch/dist/materia/bootstrap.min.css';

function App() {
    return (
        <BrowserRouter>
            <div className="container mt-5">
                <Helmet>
                    <meta charset="UTF-8"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                    <title>세상의 모든 계산기 - 세모계</title>
                    <meta name="description" content="세상의 모든 계산기를 한 곳에서 만나보세요." />
                    <meta name="keywords" content="나이 계산기, 생년월일, 나이 계산, 연령 계산, 사칙연산, 공학, 이자"/>
                
                    <meta name="author" content="oulterLogics"/>
                
                    <meta name="robots" content="index, follow"/>
                
                    <meta property="og:title" content="세상의 모든 계산기 - 세모계"/>
                
                    <meta property="og:description" content="세상의 모든 계산기를 한 곳에서 만나보세요"/>
                
                    <meta property="og:url" content="calc.oulter.com"/>

                    <meta property="og:image" content="http://calc.oulter.com/favicon-96x96.png" />

                    <meta property="og:site_name" content="세상의 모든 계산기 - 세모계"/>
                
                    <meta property="og:type" content="website"/>
                </Helmet>
                <CustomNavbar />
                <Routes>
                    {/*<Route path="/calculator" element={<Calculator />} />*/}
                    <Route path="/lotto" element={<Lotto />} />
                    <Route path="/ecalculator" element={<ECalculator />} />
                    <Route path="/age-calculator" element={<AgeCalculator />} />
                    <Route path="/interest-calculator" element={<InterestCalculator />} />
                    <Route path="/per-calculator" element={<PerCalculator />} />
                    <Route path="/" element={<AgeCalculator />} />
                    <Route path="/p-Formatter" element={<PrettierFormatter />} />
                    <Route path="/weather" element={<Weather />} />
                </Routes>
                <Footer />
            </div>
        </BrowserRouter>
    );
}

export default App;
