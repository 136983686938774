import React, { useState, useEffect } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
//import 'bootswatch/dist/coder/bootstrap.min.css'; // CoderPro 테마 CSS 추가

function AgeCalculator() {
    const [birthdate, setBirthdate] = useState('');
    const [currentDate, setCurrentDate] = useState('');
    const [result, setResult] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const timeRef = React.useRef(null);

    useEffect(() => {
        setTodayDate();
    }, []);

    const setTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); 
        const day = String(today.getDate()).padStart(2, '0');
    
        setCurrentDate(`${year}-${month}-${day}`);
    }
    
    const validateInputs = () => {
      const birthDateObj = new Date(birthdate);
      const currentDateObj = new Date(currentDate);

      // 유효성 검사 시작
      if (isNaN(birthDateObj.getTime())) {
        return "생년월일를 입력해주세요.";
      }
      if (isNaN(currentDateObj.getTime())) {
        return "기준날짜를 입력해주세요.";
      }
      if (birthDateObj > currentDateObj) {
        return "생년월일은 기준날짜보다 클 수 없습니다.";
      }

      return "";
  }

  const handleFromTimeKeyDown = (e) => {
      if (e.key === 'Enter') {
          timeRef.current.focus();
      }
  }

    const calculateAge = () => {
        const birthDateObj = new Date(birthdate);
        const currentDateObj = new Date(currentDate);

        const error = validateInputs();
        
        if (error) {
            setErrorMessage(error);
            setResult('');
            return;
        }

        setErrorMessage('');

        // 유효성 검사 종료

        let age = currentDateObj.getFullYear() - birthDateObj.getFullYear();
        const m = currentDateObj.getMonth() - birthDateObj.getMonth();
        if (m < 0 || (m === 0 && currentDateObj.getDate() < birthDateObj.getDate())) {
            age--;
        }

        setResult("당신의 나이는 " + age + "세입니다.");
    }

    return (
        <div className="container mt-5">
            <h1 className="text-center">나이</h1>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            <Form.Group className="mb-3">
                <Form.Label><b>생년월일:</b></Form.Label>
                <Form.Control type="date" value={birthdate} onKeyDown={handleFromTimeKeyDown} onChange={e => setBirthdate(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label><b>기준날짜:</b></Form.Label>
                <Form.Control type="date" ref={timeRef} value={currentDate} onChange={e => setCurrentDate(e.target.value)} />
            </Form.Group>
            <Button variant="success" onClick={calculateAge} className="mb-3">나이 계산</Button>
            <Alert className="mt-4" variant="info">
              {result}
            </Alert>
        </div>
    );
}

export default AgeCalculator;
