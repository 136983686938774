import React, { useState, useEffect } from 'react';
import '../calculator.css';

function Calculator() {
    const [input, setInput] = useState("");
    const [result, setResult] = useState(null);
    
    useEffect(() => {
        const handleKeyDown = (event) => {
            switch (event.key) {
                case "0":
                case "1":
                case "2":
                case "3":
                case "4":
                case "5":
                case "6":
                case "7":
                case "8":
                case "9":
                case "+":
                case "-":
                case "*":
                case "/":
                    setInput(prev => prev + event.key);
                    break;
                case "Enter":
                    handleCalculate();
                    break;
                case "Escape":
                    handleClear();
                    break;
                case "Backspace":
                    setInput(prev => prev.slice(0, -1));
                    break;
                default:
                    break;
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    // eslint-disable-next-line no-use-before-define
    }, [handleCalculate, input]);

    const operations = {
        "+": (a, b) => a + b,
        "-": (a, b) => a - b,
        "*": (a, b) => a * b,
        "/": (a, b) => b !== 0 ? a / b : "0"
    };

    const calculateExpression = (expression) => {
        for (const [operator, operation] of Object.entries(operations)) {
            const operands = expression.split(operator);
            if (operands.length === 2) {
                const [a, b] = operands.map(op => parseFloat(op));
                return operation(a, b);
            }
        }
        return "0";
    }

    const handleClick = (value) => {
        setInput(prev => prev + value);
    };

    const handleCalculate = () => {
        setResult(calculateExpression(input));
    };

    const handleClear = () => {
        setInput("");
        setResult(null);
    };

    return (
        <div className="container mt-5">
            <h1 className="text-center">일반</h1>
            <div className="ml-2 mr-2 calculator-container">
                <div className="display">
                    {result || input || "0"}
                </div>
                <div className="buttons-grid">
                    <button className="button" onClick={() => handleClick("7")}>7</button>
                    <button className="button" onClick={() => handleClick("8")}>8</button>
                    <button className="button" onClick={() => handleClick("9")}>9</button>
                    <button className="button" onClick={() => handleClick("/")}>÷</button>
                    <button className="button" onClick={() => handleClick("4")}>4</button>
                    <button className="button" onClick={() => handleClick("5")}>5</button>
                    <button className="button" onClick={() => handleClick("6")}>6</button>
                    <button className="button" onClick={() => handleClick("*")}>×</button>
                    <button className="button" onClick={() => handleClick("1")}>1</button>
                    <button className="button" onClick={() => handleClick("2")}>2</button>
                    <button className="button" onClick={() => handleClick("3")}>3</button>
                    <button className="button" onClick={() => handleClick("-")}>-</button>
                    <button className="button" onClick={() => handleClick("0")}>0</button>
                    <button className="button" onClick={handleClear}>AC</button>
                    <button className="button" onClick={handleCalculate}>=</button>
                    <button className="button" onClick={() => handleClick("+")}>+</button>
                </div>
            </div>
        </div>
    );
}

export default Calculator;
