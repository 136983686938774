import React, { useState } from 'react';
import * as math from 'mathjs';
import '../e_calculator.css';

function Calculator() {
  const [input, setInput] = useState("");
  const [result, setResult] = useState("");

  const handleClick = (value) => {
    setInput(prev => prev + value);
  };

  const handleCalculate = () => {
    try {
      const res = math.evaluate(input);
      setResult(res);
    } catch (e) {
      setResult("Error");
    }
  };

  const handleClear = () => {
    setInput("");
    setResult("");
  };

  return (
    <div className="container mt-5 pr-1">
        <h1 className="text-center">공학</h1>
        <div className="calculator">
        <div className="display">
            <div className="input">{input}</div>
            <div className="result">{result}</div>
        </div>
        <div className="buttons">
            <button onClick={() => handleClick('7')}>7</button>
            <button onClick={() => handleClick('8')}>8</button>
            <button onClick={() => handleClick('9')}>9</button>
            <button onClick={() => handleClick('/')}>/</button>
            <button onClick={() => handleClick('4')}>4</button>
            <button onClick={() => handleClick('5')}>5</button>
            <button onClick={() => handleClick('6')}>6</button>
            <button onClick={() => handleClick('*')}>*</button>
            <button onClick={() => handleClick('1')}>1</button>
            <button onClick={() => handleClick('2')}>2</button>
            <button onClick={() => handleClick('3')}>3</button>
            <button onClick={() => handleClick('-')}>-</button>
            <button onClick={() => handleClick('0')}>0</button>
            <button onClick={() => handleClick('.')}>.</button>
            <button onClick={handleCalculate}>=</button>
            <button onClick={() => handleClick('+')}>+</button>
            <button onClick={handleClear}>C</button>
            <button onClick={() => handleClick('(')}>(</button>
            <button onClick={() => handleClick(')')}>)</button>
            <button onClick={() => handleClick('^')}>^</button>
            <button onClick={() => handleClick('sqrt(')}>sqrt(</button>
            <button onClick={() => handleClick('log(')}>log(</button>
            <button onClick={() => handleClick('ln(')}>ln(</button>
            <button onClick={() => handleClick('sin(')}>sin(</button>
            <button onClick={() => handleClick('cos(')}>cos(</button>
            <button onClick={() => handleClick('tan(')}>tan(</button>
            <button onClick={() => handleClick('asin(')}>asin(</button>
            <button onClick={() => handleClick('acos(')}>acos(</button>
            <button onClick={() => handleClick('atan(')}>atan(</button>
            <button onClick={() => handleClick('factorial(')}>!</button>
            <button onClick={() => handleClick('abs(')}>abs(</button>
            <button onClick={() => handleClick('exp(')}>exp(</button>
        </div>
        </div>
    </div>
  );
}

export default Calculator;