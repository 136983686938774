import React, { useState } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import '../calculator.css';

function Calculator() {
    const [amount, setAmount] = useState('');
    const [percentage, setPercentage] = useState('');
    const [baseAmount, setBaseAmount] = useState('');
    const [newAmount, setNewAmount] = useState('');
    const [result, setResult] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const formatWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleInputChange = (e, setter) => {
        const rawValue = e.target.value.replace(/,/g, ''); // 쉼표 제거
        if (!isNaN(rawValue) && rawValue.trim() !== '') {
            setter(rawValue);
            e.target.value = formatWithCommas(rawValue); // 입력된 값에 쉼표 추가
        } else {
            setter('');
        }
    };

    const validateInputs = (inputValues) => {
        for (const value of inputValues) {
        if (value === '') {
            setErrorMessage('올바른 숫자 값을 입력하세요.');
            return false;
        }
        }
        setErrorMessage('');
        return true;
    };

    // 계산 함수들을 수정하여 validateInputs를 호출하도록 합니다.
    const calculatePercentage = () => {
        if (validateInputs([amount, percentage])) {
        setResult(`${formatWithCommas(amount)}의 ${percentage}%는 ${formatWithCommas((amount * (percentage / 100)).toFixed(1))}입니다.`);
        }
    };

    const calculateIncrease = () => {
        setResult(`${formatWithCommas(amount)}의 ${percentage}% 증가는 ${formatWithCommas((amount * (1 + percentage / 100)).toFixed(1))}입니다.`);
    };

    const calculateDecrease = () => {
        setResult(`${formatWithCommas(amount)}의 ${percentage}% 감소는 ${formatWithCommas((amount * (1 - percentage / 100)).toFixed(1))}입니다.`);
    };

    const calculatePercentageOfAmount = () => {
        setResult(`${formatWithCommas(newAmount)}은 ${formatWithCommas(baseAmount)}의 ${formatWithCommas((newAmount / baseAmount * 100).toFixed(1))}% 입니다.`);
    };

    const calculatePercentageDifference = () => {
        const difference = ((newAmount - baseAmount) / baseAmount) * 100;
        const direction = difference > 0 ? '증가' : '감소';
        setResult(`${formatWithCommas(newAmount)}은 ${formatWithCommas(baseAmount)} 대비 ${formatWithCommas(Math.abs(difference).toFixed(1))}% ${direction}입니다.`);
    };

    return (
        <div className="container mt-5">
            <h1 className="text-center">퍼센트</h1>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            <Form.Group className="mb-2">
                <div>
                <Form.Label><b>금액:</b></Form.Label>
                </div>
                <div>
                <input
                type="text"
                className="form-control"
                value={formatWithCommas(amount)}
                onChange={(e) => handleInputChange(e, setAmount)}
                placeholder="금액"
                />
                </div>
                <div>
                <Form.Label><b>퍼센트:</b></Form.Label>
                </div>
                <div>
                <input
                type="text"
                className="form-control"
                value={percentage}
                onChange={(e) => handleInputChange(e, setPercentage)}
                placeholder="퍼센트"
                />
                </div>
                <div className="mt-2 mb-2">
                <Button onClick={calculatePercentage}>몇퍼센트</Button>
                <Button onClick={calculateIncrease}>증가</Button>
                <Button onClick={calculateDecrease}>감소</Button>
                </div>
                <div>
                <Form.Label><b>기준 금액:</b></Form.Label>
                </div>
                <div>
                <input
                type="text"
                className="form-control"
                value={formatWithCommas(baseAmount)}
                onChange={(e) => handleInputChange(e, setBaseAmount)}
                placeholder="기준 금액"
                />
                </div>
                <div>
                <Form.Label><b>새로운 금액:</b></Form.Label>
                </div>
                <div>
                <input
                type="text"
                className="form-control"
                value={formatWithCommas(newAmount)}
                onChange={(e) => handleInputChange(e, setNewAmount)}
                placeholder="새로운 금액"
                />
                </div>
                <div className="mt-2 mb-2">
                    <Button onClick={calculatePercentageOfAmount} variant="success">몇퍼센트</Button>
                     
                    <Button onClick={calculatePercentageDifference} variant="success">증감</Button>
                </div>
                <div>
                    <Form.Label><b>결과:</b></Form.Label>
                </div>
                <div>
                    <Alert className="mt-4" variant="info">
                        {result}
                    </Alert>
                </div>
            </Form.Group>
            <div className="usage">
            <h2>계산기 사용법</h2>
            <p><code>[금액][퍼센트]=[몇퍼센트]</code>: </p><p>금액의 퍼센트를 계산합니다.</p>
            <p><code>[금액][퍼센트]=[증가]</code>: </p><p>금액의 퍼센트 증가를 계산합니다.</p>
            <p><code>[금액][퍼센트]=[감소]</code>: </p><p>금액의 퍼센트 감소를 계산합니다.</p>
            <p><code>[기준금액][새로운금액]=[몇퍼센트]</code>: </p><p>기준금액 대비 금액이 몇 퍼센트인지 계산합니다.</p>
            <p><code>[기준금액][새로운금액]=[증감]</code>: </p><p>기준금액 대비 금액이 몇 퍼센트 증가 또는 감소 했는지 계산합니다.</p>
            </div>
        </div>
    );
}

export default Calculator;
