import React, { useState, useEffect, useRef } from 'react';
import * as sqlFormatter from 'sql-formatter';
import '../calculator.css';

const PrettierFormatter = () => {
    const [code, setCode] = useState('');
    const [formattedCode, setFormattedCode] = useState('');
    const [language, setLanguage] = useState('html');
    const formattedCodeRef = useRef(null); // 복사할 텍스트를 참조하기 위한 ref

    useEffect(() => {
        const scripts = [
            "https://cdn.jsdelivr.net/npm/prettier@2.3.2/standalone.js",
            "https://cdn.jsdelivr.net/npm/prettier@2.3.2/parser-babel.js",
            "https://cdn.jsdelivr.net/npm/prettier@2.3.2/parser-html.js",
            "https://cdn.jsdelivr.net/npm/prettier@2.3.2/parser-postcss.js"
        ];

        scripts.forEach(src => {
            const script = document.createElement('script');
            script.src = src;
            script.async = true;
            document.body.appendChild(script);
        });
    }, []);

    const formatSQL = (code) => {
        return sqlFormatter.format(code); // `sql-formatter`를 사용하여 SQL을 포맷팅
    };

    const handleFormat = () => {
        try {
            if (language === 'sql') { // SQL 선택 시
                setFormattedCode(formatSQL(code));
                return;
            }

            const formatted = window.prettier.format(code, {
                parser: language,
                plugins: window.prettierPlugins
            });
            setFormattedCode(formatted);
        } catch (error) {
            console.error('Prettier format error:', error);
        }
    };

    const handleCopyToClipboard = () => {
        if (navigator.clipboard && typeof navigator.clipboard.writeText === "function") {
            try {
                navigator.clipboard.writeText(formattedCode).then(() => {
                    //alert('Copied to clipboard!');
                }).catch(err => {
                    console.error('복사오류: ', err);
                });
            } catch (err) {
                console.error('복사오류: ', err);
            }
        } else {
            // Fallback to execCommand for older browsers or HTTP environments
            const textarea = document.createElement('textarea');
            textarea.value = formattedCode;
            document.body.appendChild(textarea);
            textarea.select();
            try {
                const successful = document.execCommand('copy');
                if (successful) {
                    //alert('Copied to clipboard!');
                } else {
                    console.error('복사오류');
                }
            } catch (err) {
                console.error('복사오류: ', err);
            } finally {
                document.body.removeChild(textarea);
            }
        }
    };

  return (
<div className="container mt-4">
            <div className="row mb-4">
                <div className="col-md-12">
                    <h2>코드 포맷터</h2>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-6">
                    <label htmlFor="codeTypeSelect">코드종류:</label>
                    <select 
                        className="form-control" 
                        id="codeTypeSelect"
                        value={language} 
                        onChange={e => setLanguage(e.target.value)}
                    >
                        <option value="html">HTML</option>
                        <option value="css">CSS</option>
                        <option value="json">JSON</option>
                        <option value="babel">JavaScript</option>
                        <option value="sql">SQL</option>
                    </select>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-12">
                    <textarea 
                        className="form-control"
                        rows="5"
                        value={code} 
                        onChange={(e) => setCode(e.target.value)}
                        placeholder="코드를 입력하세요..."
                    />
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-12">
                    <button className="btn btn-primary mr-2" onClick={handleFormat}>정렬</button>
                    <button className="btn btn-secondary" onClick={handleCopyToClipboard}>복사</button>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <textarea 
                        className="form-control"
                        ref={formattedCodeRef} // 이 textarea에 ref를 연결합니다
                        rows="5"
                        id="fmCode"
                        value={formattedCode}
                        readOnly 
                        placeholder="정렬 코드가 이곳에 표시됩니다..."
                    />
                </div>
            </div>
        </div>
  );
};

export default PrettierFormatter;