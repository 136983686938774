import React, { useState } from 'react';
import { Button, Alert } from 'react-bootstrap';

function InterestCalculator() {// 원래 상태
    const [principal, setPrincipal] = useState(0);
    const [rate, setRate] = useState(0);

    // 표시될 문자열 상태
    const [principalStr, setPrincipalStr] = useState("0");
    const [rateStr, setRateStr] = useState("0");

    const [time, setTime] = useState(0);
    const [result, setResult] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [principalKorean, setPrincipalKorean] = useState("");
    const [rateKorean, setRateKorean] = useState("");
    const [timeKorean, setTimeKorean] = useState("");

    const rateRef = React.useRef(null);
    const timeRef = React.useRef(null);

    const validateInputs = () => {
        if (principal <= 0 || isNaN(principal)) {
            return "원금을 올바르게 입력해주세요.";
        }
        if (rate <= 0 || isNaN(rate)) {
            return "이자율을 올바르게 입력해주세요.";
        }
        if (time <= 0 || isNaN(time)) {
            return "기간을 올바르게 입력해주세요.";
        }
        return "";
    }

    const convertPrincipalToKorean = () => {
        const value = principal / 10000;

        if(value > 0){
            if(value >= 10000){
                const valuea = value / 10000;
                setPrincipalKorean(`${numberWithCommas(valuea.toFixed(2))}억원`);
            }
            else{
                setPrincipalKorean(`${numberWithCommas(value.toFixed(2))}만원`);
            }
        }
    }

    const convertRateToKorean = () => {
        if (rate > 0){
            setRateKorean(`${rate}%`);
        }
    }

    const convertTimeToKorean = () => {
        if(time > 0){
            if (time > 11) {
                setTimeKorean(`${numberWithCommas((time / 12).toFixed(2))}년`);
            } else {
                setTimeKorean(`${numberWithCommas(time.toFixed(2))}개월`);
            }
        }
    }

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const handlePrincipalChange = (e) => {
        let value = e.target.value.replace(/,/g, '');  // 쉼표 제거 
        value = value.replace(/^0+/, '');  // 맨 앞의 연속된 0 제거
        if (value === '') value = '0';  // 모든 값이 제거된 경우 0으로 재설정
        setPrincipalStr(value);
        setPrincipal(parseFloat(value));
    }

    const handleRateChange = (e) => {
        let value = e.target.value.replace(/,/g, '');  // 쉼표 제거

        if(rate >= 1){
            value = value.replace(/^0+/, '');  // 맨 앞의 연속된 0 제거
        }
        else{
        }

        if (value === '') value = '0';  // 모든 값이 제거된 경우 0으로 재설정
        setRateStr(value);
        setRate(parseFloat(value));
    }
    
    const handleTimeChange = (e) => {
        let value = e.target.value;
        value = value.replace(/^0+/, '');  // 맨 앞의 연속된 0 제거
        if (value === '') value = '0';  // 모든 값이 제거된 경우 0으로 재설정
        setTime(parseFloat(value));
    }

    const handlePrincipalKeyDown = (e) => {
        if (e.key === 'Enter') {
            rateRef.current.focus();
        }
    }

    const handleRateKeyDown = (e) => {
        if (e.key === 'Enter') {
            timeRef.current.focus();
        }
    }

    const calculateInterest = () => {
        const error = validateInputs();
        if (error) {
            setErrorMessage(error);
            setResult('');
            return;
        }

        setErrorMessage('');
        // 월별 복리 계산
        const compoundInterest = principal * Math.pow((1 + rate / 1200), time) - principal;

        if(compoundInterest >= 10000) {
            const compoundI = compoundInterest / 10000;

            if(compoundI >= 10000){
                const compoundIa = compoundI / 10000;
                setResult('총 ' + numberWithCommas(compoundIa.toFixed(1)) + ' 억원');
            }
            else {
                setResult('총 ' + numberWithCommas(compoundI.toFixed(1)) + ' 만원');
            }
        }
        else {
            setResult('총 ' + numberWithCommas(compoundInterest.toFixed(2)) + ' 원');
        }
    };

    return (
        <div className="container mt-5">
            <h1 className="text-center">이자율</h1>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            <div className="mb-3">
                <label className="form-label"><b>원금:</b> <span>{principalKorean}</span></label>
                <input type="text" className="form-control" value={principalStr} onKeyDown={handlePrincipalKeyDown} onChange={handlePrincipalChange} onBlur={() => { convertPrincipalToKorean(); setPrincipalStr(numberWithCommas(principalStr)) }} />
            </div>
            <div className="mb-3">
                <label className="form-label"><b>이자율(%):</b> <span>{rateKorean}</span></label>
                <input type="text" ref={rateRef} className="form-control" value={rateStr} onKeyDown={handleRateKeyDown} onChange={handleRateChange} onBlur={() => { convertRateToKorean(); }} />
            </div>
            <div className="mb-3">
                <label className="form-label"><b>기간:</b> <span>{timeKorean}</span></label>
                <input type="text" ref={timeRef} className="form-control" value={time.toString()} onChange={handleTimeChange} onBlur={convertTimeToKorean} />
            </div>
            <Button variant="success" onClick={calculateInterest} className="mb-3">이자 계산</Button>
            <Alert className="mt-4" variant="info">
              {result}
            </Alert>
        </div>
    );
}

export default InterestCalculator;